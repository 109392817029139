import React from 'react';
import axios from 'cccisd-axios';
import PropTypes from 'prop-types';
import style from '../style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

export default class RolePickerGroup extends React.Component {
    static propTypes = {
        groupPawns: PropTypes.array,
        backgroundImage: PropTypes.string,
        color: PropTypes.string,
    };

    loginAs = async user => {
        const response = await axios.post(Boilerplate.route('api.nexus.user.changeTo'), user);
        if (response.data.status === 'success') {
            window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
        }
    };

    getAccountType = role => {
        if (role === 'orgAdmin') {
            return 'Org Admin';
        }
        if (role === 'orgTeamMember') {
            return 'Org Team Member';
        }
        return 'User';
    };

    getCardType = pawn => {
        const role = pawn.data_type;

        if (role.includes('org')) {
            return 'Organization';
        }
        if (role === 'uberadmin') {
            return 'Uber Admin';
        }
        if (role === 'instructor') {
            return 'My Well-being';
        }
        return null;
    };

    getButtonLabel = pawn => {
        const role = pawn.data_type;

        if (role === 'uberadmin') {
            return 'Uber Admin';
        }
        if (role === 'instructor') {
            return 'Provider';
        }
        if (role.includes('org')) {
            return pawn.group.label;
        }
        return null;
    };

    render() {
        const { groupPawns, backgroundImage, color } = this.props;

        const cardTitle = this.getCardType(groupPawns[0]);

        return (
            <div className={style.groupSelect}>
                <div
                    className={style.image}
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                />
                <div className={style.flagWrap}>
                    <div className={style.flag} style={{ backgroundColor: color }} />
                </div>
                <div className={style.groupText}>
                    {cardTitle} {cardTitle === 'My Well-being' ? 'Account' : 'Accounts'}
                </div>
                {groupPawns &&
                    groupPawns.map((pawn, index) => {
                        const label = this.getButtonLabel(pawn);
                        return (
                            <button
                                key={index}
                                type="button"
                                className={`btn btn-lg btn-primary ${style.button}`}
                                onClick={() =>
                                    this.loginAs({
                                        pawn: pawn.id,
                                        pawnHash: pawn.random_hash,
                                    })
                                }
                            >
                                {label}
                                {pawn.data_type !== 'uberadmin' && pawn.data_type !== 'instructor' && (
                                    <div style={{ fontSize: '14px' }}>
                                        {'('} {this.getAccountType(pawn.data_type)} {')'}
                                    </div>
                                )}
                            </button>
                        );
                    })}
            </div>
        );
    }
}
