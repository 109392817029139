import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'cccisd-react-router';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import { getUserGroups } from 'js/reducers/providerwellbeing.js';
import RolePickerGroup from './RolePickerGroup';
import style from './style.css';

import providerIcon from '../../../images/providerIcon.png';
import orgIcon from '../../../images/orgIcon.png';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

const mapStateToProps = (state, props) => ({
    userGroups: state.app.providerwellbeing.userGroups,
});

export default connect(mapStateToProps, { getUserGroups })(
    class RolePicker extends React.Component {
        static propTypes = {
            userGroups: PropTypes.array,
            getUserGroups: PropTypes.func,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            await this.props.getUserGroups();
            let response = await axios.get(Boilerplate.route('pawn.pending.requests'));
            const userData = this.getUserData();

            this.setState({
                pendingRequests: response.data.pendingRequests,
                loading: false,
                userData,
            });
        };

        getUserData = () => {
            const { userGroups } = this.props;

            let providerPawns = userGroups.filter(user => user.data_type === 'instructor');
            let orgAdminPawns = userGroups.filter(user => user.data_type === 'orgAdmin');
            let orgTeamMemberPawns = userGroups.filter(user => user.data_type === 'orgTeamMember');

            let orgPawns = userGroups.filter(
                user => user.data_type === 'orgAdmin' || user.data_type === 'orgTeamMember'
            );

            let uberPawns = userGroups.filter(user => user.data_type === 'uberadmin');

            return {
                providerPawns,
                orgAdminPawns,
                orgTeamMemberPawns,
                orgPawns,
                uberPawns,
            };
        };

        renderJoinButton = () => {
            const userTypes = this.props.userGroups.map(u => u.data_type);
            if (
                userTypes.includes('instructor') &&
                userTypes.some(t => t === 'orgAdmin' || t === 'orgTeamMember')
            ) {
                return null;
            }
            if (!userTypes.some(t => t === 'instructor')) {
                return (
                    <NavLink to="/signup">
                        <button type="button" className="btn btn-primary">
                            Register as a Provider
                        </button>
                    </NavLink>
                );
            }
            if (!userTypes.some(t => t === 'orgAdmin' || t === 'orgTeamMember')) {
                return (
                    <NavLink to="/signup">
                        <button type="button" className="btn btn-primary">
                            Join or register an Organization
                        </button>
                    </NavLink>
                );
            }
            return null;
        };

        render() {
            if (this.state.loading) {
                return <Loader loading />;
            }
            if (this.state.userData) {
                const { providerPawns, orgPawns, uberPawns } = this.state.userData;

                return (
                    <div>
                        {providerPawns.length > 0 || orgPawns.length > 0 || uberPawns.length > 0 ? (
                            <div
                                className={style.subheading}
                                style={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                                <span className={style.subheadingTitle}>
                                    Please choose which account you would like to visit
                                </span>
                                <div>{this.renderJoinButton()}</div>
                            </div>
                        ) : (
                            <div className={style.subheading}>
                                <span className={style.subheadingTitle}>
                                    Your accounts will appear here when you join one or more teams.
                                </span>
                            </div>
                        )}
                        <div className={style.groupSelectWrapper}>
                            {providerPawns.length >= 1 && (
                                <RolePickerGroup
                                    groupPawns={providerPawns}
                                    backgroundImage={providerIcon}
                                    color="#FEA92F"
                                />
                            )}

                            {orgPawns.length >= 1 && (
                                <RolePickerGroup
                                    groupPawns={orgPawns}
                                    backgroundImage={orgIcon}
                                    color="#859A20"
                                />
                            )}

                            {uberPawns.length >= 1 && (
                                <RolePickerGroup
                                    groupPawns={uberPawns}
                                    backgroundImage={providerIcon}
                                    color="#D32202"
                                />
                            )}
                        </div>
                    </div>
                );
            }
            return null;
        }
    }
);
